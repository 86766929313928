(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

document.addEventListener('DOMContentLoaded', function () {
    /* globals $ jQuery */
    jQuery(document).ready(function ($) {
        // load the laravel csrf token
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('select').not('.no-select2').not('[multiple]').select2({
            theme: "bootstrap"
        });

        $('select[multiple]').not('.no-select2').select2();

        $('[data-date]').on('click', function (event) {
            event.preventDefault();
            var $t = $(this);
            var outside = $t.attr('data-date');
            var inside = $t.text();
            $t.attr('data-date', inside);
            $t.text(outside);
            return false;
        });

        $('button.delBot').on('click', function () {
            var itemId = $(this).attr('data-id');
            swal({
                title: "Are you sure?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!"
            }).then(function (isConfirm) {
                if (isConfirm === true) {
                    $('#submit' + itemId).submit();
                };
            });
        });

        $('button.hasTicketsAssigned').on('click', function () {
            var itemId = $(this).attr('data-id');
            var item = $(this);

            // Get Users List And Lunch Swal when finished
            $.ajax({
                type: "GET",
                url: "staff-list",
                success: function success(result) {
                    swal({
                        title: "Are you sure?",
                        text: "Please select another Staff member for this User's Tickets:",
                        type: "warning",
                        input: "select",
                        inputOptions: result,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes",
                        showCancelButton: true,
                        closeOnConfirm: false
                    }).then(function (user) {
                        if (user) {
                            $('form#submit' + itemId + ' :input[name="replace_user"]').val(user);
                            $('#submit' + itemId).submit();
                        }
                    });
                }
            });
        });
        // DrowDown selected State
        $('.stateFilter').text($('.stateFilter').attr('data'));

        $('.dropdown-inverse li > a').click(function (e) {
            $('.stateFilter').text(this.innerHTML);
        });

        $('.btn-edit').click(function () {
            var boxId = $(this).attr('data');
            if ($('#' + boxId).is(":visible")) {
                $('#' + boxId).hide();
            } else {
                $('#' + boxId).show();
            }
        });

        function updateTicketState(action, state) {
            $.ajax({
                type: "POST",
                url: action,
                data: { state: state }
            }).then(function (res) {
                swal({
                    title: "Ticket Updated",
                    text: res.message,
                    type: "success"
                });
            }, function (err) {
                console.log(err);
                $('#wrapper').html(err.responseText);
            });
        }

        $('#state-change').on('change', function () {
            var action = $(this).attr('data-url');
            var state = $(this).val();

            swal({
                title: "Update the state of this ticket?",
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes"
            }).then(function (isConfirm) {
                if (isConfirm === true) {
                    updateTicketState(action, state);
                }
            });
        });

        $('#submission-state-change').on('change', function () {
            var action = $(this).attr('data-url');
            var state = $(this).val();

            swal({
                title: "Update the state of this submission?",
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes"
            }).then(function (isConfirm) {
                if (isConfirm === true) {
                    updateSubmissionState(action, state);
                }
            });
        });

        function updateSubmissionState(action, state) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.post(action, {
                state: state
            }).then(function (res) {
                swal({
                    title: "Submission Updated",
                    text: res.message,
                    type: "success"
                });
            }, function (err) {
                console.log(err);
            });
        }

        $('#ajaxSaveContact').click(function () {
            createNewContact();
        });
        $('#newContact').click(function () {
            $('#contact_school').select2({
                theme: "bootstrap",
                width: '100%'
            });
            $('#role').select2({
                theme: "bootstrap",
                width: '100%'
            });
            $('#createContact').show();
        });

        $('#close-contactbox').click(function () {
            $('#createContact').hide();
        });

        function createNewContact() {
            var formData = {
                contact: {
                    school_id: $("#contact_school").val(),
                    first_name: $("#first_name").val(),
                    last_name: $("#last_name").val(),
                    email: $("#email").val(),
                    role: $("#role").val(),
                    phone_number: $("#phone_number").val(),
                    notes: $("#notes").val()
                }
            };

            $.ajax({
                type: "POST",
                url: "/contact/store-ajax",
                data: formData,
                success: function success(result) {
                    // add option to select
                    var newOption = new Option(result.data.first_name + ' ' + result.data.last_name, result.data.id, true, true);
                    $("#contact_id").append(newOption).trigger('change');
                    // Confirmation
                    swal({
                        title: "Contact Created",
                        text: result.message,
                        type: "success"
                    });
                    // Reset Form & Close
                    resetContactForm();
                    $('#form-errors').hide();
                    $('#createContact').hide();
                }, error: function error(jqXhr, json, errorThrown) {
                    // Format Errors
                    var errors = jqXhr.responseJSON;
                    var errorsHtml = $('#form-errors').html();
                    $.each(errors, function (key, value) {
                        errorsHtml += '<li>' + value[0] + '</li>';
                    });
                    // Display in Form and go to
                    $('#form-errors').html(errorsHtml).fadeIn();
                    $('html, body').animate({
                        scrollTop: $("#form-errors").offset().top - 75
                    }, 500);
                }
            });
        }

        function resetContactForm() {
            $("#contact_school").val('');
            $("#first_name").val('');
            $("#last_name").val('');
            $("#email").val('');
            $("#role").val('');
            $("#phone_number").val('');
            $("#notes").val('');
        }

        if ($.fn.datepicker) {
            $(".date-picker").datepicker();
        }
    });
});

},{}]},{},[1]);
